
.pac-container {
    border-radius: 21px;
    padding-left: 9px;
    padding-right: 9px;
    &.pac-logo::after {
        display: none;
        content: none;
      }
    .pac-item{
        padding: 3px;
    }
  }
  