.list-container {
    // padding: 10px 10px !important;
    padding-top: 10px !important; 
    padding-left: 16px !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 0.7rem !important;
    justify-content: center !important;
    // margin-left: 0.8rem !important;
}
  