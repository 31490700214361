.body {
    background-color: #f5f5f5;
    // font-family: 'Inter', sans-serif;
}

/* .container{
    width: 1080px !important;
    max-width: 1080px !important;
} */
.navbar {
    border-bottom: 1px solid #f2f2f2;
}

.hy {
    background-image: url('../images/dummyimages.jpeg');
    border-radius: 10px;
    opacity: 1;
}

.card {
    height: 156px;
}

.event-location {
    display: flex;
    justify-content: center;
}

.Search-img {
    position: absolute;
    margin-top: 15px;
    margin-left: 15px;
}

.form-control-search {
    background: #f2f2f2;
    border: 1px solid #f4f4f4;
    padding: 12px;
    border-radius: 21px;
    /* min-width: 180vh !important; */
    padding-left: 45px;
    height: 47px;

}

.button {
    border: none;
    border-radius: 21px;
    cursor: pointer;
}

.wimbley {
    background: #f2f2f2 !important;
    height: 80px;
    border-radius: 11px;
}

#customRange1 {
    color: yellow;
}

.hz {
    height: 150px !important;
    background-image: url('../images/dummyimages.jpeg ');
    border-radius: 10px;
    opacity: 1;
}
.hzy{
    min-height: 150px;
    background-image: url('../images/d-wedding.png');
}